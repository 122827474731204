<template>
  <el-main>
    <el-button style="margin-bottom:15px;" icon="el-icon-plus" type="primary" size="small" @click="$router.push('/extension/vote/addVote')">添加投票</el-button>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="投票名称：">
        <el-input size="small" v-model="searchForm.vote_name"></el-input>
      </el-form-item>
      <el-form-item label="投票状态：">
        <el-select size="small" v-model="searchForm.vote_status">
          <el-option label="全部" value=""></el-option>
          <el-option label="未开始" :value="1"></el-option>
          <el-option label="进行中" :value="2"></el-option>
          <el-option label="已结束" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投票时间：">
        <el-date-picker v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="cover_picture" label="列表图" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 100px; height: 70px" :src="row.cover_picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="vote_name" label="投票名称" align="center"></el-table-column>
      <el-table-column prop="vote_start_time" label="投票时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.vote_start_time) }} ~ {{ getDateformat(row.vote_end_time) }}</template>
      </el-table-column>
      <el-table-column prop="vote_person_sum" label="被投票人数" align="center"></el-table-column>
      <el-table-column prop="vote_status" label="投票状态" align="center">
        <template v-slot="{ row }">
          {{ row.vote_status == 1 ? '未开始' : row.vote_status == 2 ? '进行中' : '已结束' }}
        </template>
      </el-table-column>
      <el-table-column prop="money" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="$router.push({ path: '/extension/vote/editVote', query: { id: row.id } })" size="small" type="text">编辑</el-button>
          <el-button @click="del(row)" size="small" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        vote_name: '',
        vote_status: '',
        start_time: '',
        end_time: '',
      },
      list: [],
      total_number: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        vote_name: '',
        vote_status: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getList() {
      this.$axios.post(this.$api.sports.voteList, this.searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del(row) {
      this.$confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.sports.delVote, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>